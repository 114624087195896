import imagesLoaded from 'imagesloaded';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';

import gsap from '../lib/gsap.min';
import ScrollTrigger from '../lib/ScrollTrigger.min';
import ScrollToPlugin from '../lib/ScrollToPlugin.min';
gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import Vimeo from '@vimeo/player';

export function main() {
	
var Module = {};
var body;

Module.Compornent = {
	mobileToggleAsset: false,
	checkClass: function($this){
		$this = null;
	}
};

(function($) {
Module.Events = (function (){
'use strict';

var OBSERVER = {
	handlers: [],
	observeEvents: function(target){
		this.handlers.push(target);
	},
	deleteListener: function(element,listener){
		element.off(listener);
		element = null;
		listener = null;
	},
	clearEvents: function(){
		$(window).off('scroll resize');
		if(topkv_requestId){
			cancelAnimationFrame(topkv_requestId);
		}
	}
};

var winW = $(window).width();
var winH = $(window).height();
var body = $('body');

const winsize = () => {
	winW = $(window).width();
	winH = $(window).height();
	const vw = winW;
	document.documentElement.style.setProperty('--vw', vw+'px');
	const vh = winH;
	document.documentElement.style.setProperty('--vh', vh+'px');
}
window.addEventListener('resize', function () {
	winsize();
});

function nav_show(){
	body.addClass('navopen');
	gsap.to('#nav',{
		height: winH - 40,
		duration:0.3,
		ease: "power2.inOut",
		onComplete: () => {
			ScrollTrigger.refresh();
		}
	});
}
function nav_hide(){
	body.removeClass('navopen');
	gsap.to('#nav',{
		height: 64,
		duration:0.3,
		ease: "power2.inOut",
		onComplete: () => {
			ScrollTrigger.refresh();
		}
	});
	gsap.to('#main_nav', { duration:0.4, scrollTo:{y:0, autoKill:false}});	
}
function navInit(){
	$('#nav_btn').on('click', function(){
		if( body.hasClass('navopen') ){
			nav_hide();
		}else{
			nav_show();
		}
	});
}

function nav_pagecheck(){
	gsap.utils.toArray('#navul .nli').forEach(nli => {
		const pagename = nli.dataset.page;
		nli.classList.remove('current');
		if( document.getElementById(pagename) ){
			nli.classList.add('current');
		}
		if( document.getElementById('resources_archive_temp')||document.getElementById('whitepapers') ){
			if( pagename == 'resources' ){
				nli.classList.add('current');
			}
		}
	});
}

function nav_exClose(){
	gsap.utils.toArray('.navex').forEach(ex => {
		expand(ex, false);
	});
}


let EL_scrollTrigger;
function elFunc(EL){
	gsap.utils.toArray(EL).forEach(target => {
		if(window.matchMedia('(max-width:767px)').matches){
			if(target.classList.contains('nosp')){
				return;
			}
		}
		if(!target.classList.contains('nomove')){
			target.style.opacity = 0;
		}
		EL_scrollTrigger = ScrollTrigger.create({
			trigger: target,
			start: "top bottom-=10%",
			once: true,
			onEnter: self => {
				elAnime(target);
			},
		});
	});

	const navh = document.getElementById('nav').clientHeight;
	const up_scrollTrigger = ScrollTrigger.create({
		trigger: 'body',
		start: "top top",
		onUpdate: self => {
			const _slfscrl = self.scroll();
			if(self.direction == -1){
				document.body.classList.remove('scrldown');
			}else{
				if(_slfscrl > navh){
					if(self.prevSclrl < _slfscrl-1){
						document.body.classList.add('scrldown');
						if(subnavflag){
							nav_exClose();
						}
					}
					self.prevSclrl = _slfscrl;
				}
			}
		},
	});		
}


function elAnime(target){
	target.style.opacity = 1;
	if(target.classList.contains('elp')){
		target.classList.add('elp_on');
		var elc = target.querySelectorAll('.elc');
	}else{
		var elc = [target];
	}
	elc.forEach((elc, i) => {
		let _y = 0;
		let _opa = 0;
		if(elc.classList.contains('nomove')){
			_y = 0;
			_opa = 1;
		}
		gsap.fromTo(elc,{y:_y,opacity:_opa},{
			y: 0,
			opacity:1,
			duration: 0.8,
			delay: i * 0.1 + 0.3,
			ease:'power1.inOut',
			onComplete: function(){
				elc.classList.add('elon');
			}
		});
	});
}


let subnavflag = false;
const expand = (parent, state) => {
	if(state){
		parent.classList.add('open');
		if(parent.classList.contains('navex')){
			subnavflag = true;
		}
	}else{
		parent.classList.remove('open');
		if(parent.classList.contains('navex')){
			subnavflag = false;
		}
	}
	const exc = parent.querySelector('.exc');
	gsap.utils.toArray(exc).forEach(ex => {
		gsap.to(ex,{
			height: (!state) ? 0 : "auto",
			duration:0.3,
			ease: "power2.inOut",
			onComplete: () => {
				ScrollTrigger.refresh();
			}
		});
	});
}
const expand_event = (e) => {
	let parent = e.target.closest('.ex');
	const result = parent.classList.contains('open');
	if (result) {
		expand(parent, false);
	}else{
		if(window.matchMedia('(min-width:768px)').matches){
			gsap.utils.toArray('.navex').forEach(ex => {
				expand(ex, false);
			});
		};
		expand(parent, true);
	}
};
function expandFunc() {
	gsap.utils.toArray('.exbtn').forEach(exbtn => {
		let parent = exbtn.closest('.ex');
		expand(parent, false);
		exbtn.removeEventListener('click', expand_event);
		exbtn.addEventListener('click', expand_event);
	});
}

var tar_offset=0;
function pageScroll(){
	$('a.anchor').on('click', function(e){
		e.preventDefault();
		// e.stopPropagation();
		nav_hide();
		var hrefPageUrl = $(this).prop("href");
		hrefPageUrl = hrefPageUrl.split("#")[0];
		var currentUrl = location.href;
		currentUrl = currentUrl.split("#")[0];
		if( hrefPageUrl == currentUrl ){
			let href = this.hash;
			gsap.to(window, { duration:0.6, scrollTo:{y:href, autoKill:false, offsetY:tar_offset}, ease:'power2', onComplete:function(){
				body.addClass('scrldown');
				if(subnavflag){
					nav_exClose();
				}
			}});	
		}
	});
	$('#btt').on('click', function(){
		gsap.to(window, { duration:0.6, scrollTo:{y:0, autoKill:false}, ease:'power2'});
		return false;
	});
}



let _soundinit = false;
let _soundflag = false;
let bgaudio = new AudioContext();
let bgm_gainNode;
let hoveraudio = new AudioContext();

function audioAPI(audio, target, isLoop, volume){
	const dataurl = target.dataset.url;
	const loadSound = (url) => {
		return new Promise((resolve) => {
			const request = new XMLHttpRequest()
			request.open('GET', url, true)
			request.responseType = 'arraybuffer'
			request.onload = () => {
				audio.decodeAudioData(request.response, (buffer) => {
					resolve(buffer)
				})
			}
			request.send()
		})
	}
	const playSound = (buffer) => {
		const source = audio.createBufferSource();
		source.buffer = buffer;

		const gainNode = audio.createGain();
		if(audio == bgaudio){
			bgm_gainNode = gainNode;
		}
		gainNode.gain.value = volume;
		source.connect(gainNode);
		gainNode.connect(audio.destination);
		source.loop = isLoop;
		source.start(0);
	}
	(async () => {
		let buffer = null;
		if(!buffer){
			buffer = await loadSound(dataurl);
			playSound(buffer);
		}
	})().catch((err) => console.error(err));
}

function soundInit(){
	const bgm = document.getElementById('bgm');
	const sound_btn = document.getElementById('sound_btn');
	sound_btn.addEventListener('click', function(){
		if(!_soundinit){
			audioAPI(bgaudio, bgm, true, 0.2);
			sound_btn.classList.remove('off');
			_soundflag = true;	
			_soundinit = true;
		}else{
			bgmFunc(sound_btn);
		}
	});
}

function bgmFunc(sound_btn){
	if(_soundflag){
		bgaudio.suspend();
		sound_btn.classList.add('off');
		_soundflag = false;
	}else{
		bgaudio.resume();
		sound_btn.classList.remove('off');
		_soundflag = true;
	}
}

function hoverSoundFunc(){
	document.querySelectorAll('a').forEach(target => {
		target.addEventListener('mouseenter', function(){
			if(window.matchMedia('(max-width:767px)').matches){
				return false;
			}
			if(_soundflag){
				audioAPI(hoveraudio, hover, false, 0.2);
			}
		});
	});
}


let topkv_requestId;
function topkvFunc(){
	const seArr = [];
	document.querySelectorAll('.se').forEach(target => {
		const _se = target;
		seArr.push(_se);
	});
	let se1 = new AudioContext();
	let se2 = new AudioContext();
	let se3 = new AudioContext();
	let se4 = new AudioContext();
	let se5 = new AudioContext();
	let se6 = new AudioContext();
	let seAudioArr = [se1, se2, se3, se4, se5, se6];
	
	const _kvtitle = document.getElementById('kv_title');
	const _elmpath = document.querySelectorAll('#kv #elm path');
	const _txtpath = document.querySelectorAll('#kv #txt path');
	const _topkvelm = document.getElementById('topkvelm');
	const elmAnime = (arr) => {
		const index = posArr.indexOf(arr);
		_topkvelm.classList.remove('pos0','pos1','pos2','pos3','pos4','pos5');
		_topkvelm.classList.add('pos'+index);
		
		gsap.timeline({
			defaults: {
				duration: 1.2,
				ease:'power4.inOut',
			}
		})
		.to('#topkv', {backgroundColor:arr.bg}, '<=')
		.to('#topkvelm', {scale:arr.scale, xPercent:arr.x, yPercent:arr.y}, '<=')
		.to(_elmpath, {fill:arr.elcol}, '<=')
		.to(_txtpath, {fill:arr.eltxt}, '<=')
		.to('#kv_title', {color:arr.txt}, '<=')
		.to('#kv_title .btn', {color:arr.bg, backgroundColor:arr.txt}, '<=');

		//Change color ELM path
		const angle = screen.orientation.angle;
		if(window.matchMedia('(max-width:767px)').matches){
			if(angle == 0 || angle == 180){
				if(index == 2){
					gsap.to('#Vector_28', {fill:arr.bg, duration:1.2, ease:'power4.inOut'})
				}
				if(index == 4){
					gsap.to('#Vector_9', {fill:arr.bg, duration:1.2, ease:'power4.inOut'})
				}
				if(index == 5){
					gsap.to('#Vector_20', {fill:arr.bg, duration:1.2, ease:'power4.inOut'})
				}	
			}else{
				if(index == 1){
					gsap.to('#Vector_8', {fill:arr.bg, duration:1.2, ease:'power4.inOut'})
				}
				if(index == 2){
					gsap.to('#Vector_29', {fill:arr.bg, duration:1.2, ease:'power4.inOut'})
					gsap.to('#Vector_30', {fill:arr.bg, duration:1.2, ease:'power4.inOut'})
				}
				if(index == 4){
					gsap.to('#Vector_5', {fill:arr.bg, duration:1.2, ease:'power4.inOut'})
				}	
				if(index == 5){
					gsap.to('#Vector_24', {fill:arr.bg, duration:1.2, ease:'power4.inOut'})
				}	
			}
		}else if(window.matchMedia('(max-width:1024px)').matches){
			if(angle == 0 || angle == 180){			
				if(index == 2){
					gsap.to('#Vector_28', {fill:arr.bg, duration:1.2, ease:'power4.inOut'})
				}
				if(index == 4){
					gsap.to('#Vector_9', {fill:arr.bg, duration:1.2, ease:'power4.inOut'})
				}
			}
		}
		if(arr.txt == '#fff'){
			_kvtitle.classList.add('wh');
		}else{
			_kvtitle.classList.remove('wh');
		}
	}
	
	const posArr_pc = [
		{scale:1.1, x:45, y:15, elcol:'#FEFCF6', eltxt:'#fff', bg:'#F44500', txt:'#fff'},
		{scale:2.3, x:70, y:75, elcol:'#FEFCF6', eltxt:'#000', bg:'#A4CAD9', txt:'#000'},
		{scale:3.2, x:-98, y:90, elcol:'#4BF1A9', eltxt:'#000', bg:'#FEFCF6', txt:'#000'},
		{scale:3.2, x:155, y:55, elcol:'#4BF1A9', eltxt:'#000', bg:'#193752', txt:'#fff'},
		{scale:3.2, x:76, y:-16, elcol:'#EBF261', eltxt:'#000', bg:'#A4CAD9', txt:'#000'},
		{scale:3.2, x:-58, y:-85, elcol:'#F44500', eltxt:'#fff', bg:'#193752', txt:'#fff'},
	];
	const posArr_sp = [
		{scale:2.5, x:100, y:160, elcol:'#FEFCF6', eltxt:'#fff', bg:'#F44500', txt:'#fff'},
		{scale:4.0, x:110, y:255, elcol:'#FEFCF6', eltxt:'#000', bg:'#A4CAD9', txt:'#000'},
		{scale:4.0, x:-110, y:215, elcol:'#4BF1A9', eltxt:'#000', bg:'#FEFCF6', txt:'#000'},
		{scale:5.2, x:240, y:200, elcol:'#4BF1A9', eltxt:'#000', bg:'#193752', txt:'#fff'},
		{scale:5.5, x:140, y:75, elcol:'#EBF261', eltxt:'#000', bg:'#A4CAD9', txt:'#000'},
		{scale:4.5, x:-85, y:-15, elcol:'#F44500', eltxt:'#fff', bg:'#193752', txt:'#fff'},
	];
	const posArr_sp_landscape = [
		{scale:1.4, x:70, y:35, elcol:'#FEFCF6', eltxt:'#fff', bg:'#F44500', txt:'#fff'},
		{scale:2.3, x:70, y:85, elcol:'#FEFCF6', eltxt:'#000', bg:'#A4CAD9', txt:'#000'},
		{scale:3.2, x:-85, y:90, elcol:'#4BF1A9', eltxt:'#000', bg:'#FEFCF6', txt:'#000'},
		{scale:3.2, x:165, y:55, elcol:'#4BF1A9', eltxt:'#000', bg:'#193752', txt:'#fff'},
		{scale:3.8, x:110, y:-10, elcol:'#EBF261', eltxt:'#000', bg:'#A4CAD9', txt:'#000'},
		{scale:3.2, x:-58, y:-85, elcol:'#F44500', eltxt:'#fff', bg:'#193752', txt:'#fff'},
	];
	const posArr_tablet_portrait = [
		{scale:2.1, x:75, y:100, elcol:'#FEFCF6', eltxt:'#fff', bg:'#F44500', txt:'#fff'},
		{scale:3.0, x:80, y:155, elcol:'#FEFCF6', eltxt:'#000', bg:'#A4CAD9', txt:'#000'},
		{scale:3.4, x:-90, y:145, elcol:'#4BF1A9', eltxt:'#000', bg:'#FEFCF6', txt:'#000'},
		{scale:4.2, x:190, y:135, elcol:'#4BF1A9', eltxt:'#000', bg:'#193752', txt:'#fff'},
		{scale:4.2, x:116, y:20, elcol:'#EBF261', eltxt:'#000', bg:'#A4CAD9', txt:'#000'},
		{scale:3.8, x:-105, y:-45, elcol:'#F44500', eltxt:'#fff', bg:'#193752', txt:'#fff'},
	];

	const changeState = () => {
		if(_state != state){
			elmAnime(posArr[state-1]);
			if(_soundflag){
				if(window.matchMedia('(max-width:767px)').matches){
					return false;
				}	
				// audioAPI(seAudioArr[state-1], seArr[state-1], false, 0.2);
				// if (bgm_gainNode) {
				// 	bgm_gainNode.gain.linearRampToValueAtTime(0.2, bgaudio.currentTime);
				// 	bgm_gainNode.gain.linearRampToValueAtTime(0, bgaudio.currentTime+0.2);
				// 	setTimeout(function(){
				// 		bgm_gainNode.gain.linearRampToValueAtTime(0, bgaudio.currentTime);
				// 		bgm_gainNode.gain.linearRampToValueAtTime(0.2, bgaudio.currentTime+1);
				// 	}, 2000);
				// }
			}
			_state = state;
		}	
	}
	const _kv = document.getElementById('kv');
	let state = 1;
	let _state = 1;
	let posArr;

	const changeEvent_pc = () => {
		_kv.addEventListener('mousemove', function(e){
			let x = e.clientX;
			let y = e.clientY - window.innerHeight / 2;
			if(y < 0){
				if(x < window.innerWidth*1/3){
					state = 1;
				}else if(x < window.innerWidth*2/3){
					state = 2;
				}else{
					state = 3;
				}
			}else{
				if(x < window.innerWidth*1/3){
					state = 4;
				}else if(x < window.innerWidth*2/3){
					state = 5;
				}else{
					state = 6;
				}
			}
			changeState();
		});
		_kv.addEventListener('mouseleave', function(e){
			state = 1;
			changeState();
		});	
	}
	const changeEvent_sp = () => {
		let count = 0;  
		const changeInterval = () => {
			count++;
			if (count == 180) {
				if(state < 6){
					state++;
				}else{
					state = 1;
				}
				count = 0;
				changeState();
			}
			topkv_requestId = requestAnimationFrame(changeInterval);
		}
		const cancelAnimation = () => {
			cancelAnimationFrame(topkv_requestId);
		}
		changeInterval();
	}

	// const posSet = () => {
	// 	const angle = screen.orientation.angle;
	// 	if(window.matchMedia('(max-width:767px)').matches){
	// 		if(angle == 0 || angle == 180){
	// 			posArr = posArr_sp;
	// 		}else{
	// 			posArr = posArr_sp_landscape;
	// 		}
	// 	}else if(window.matchMedia('(max-width:1024px)').matches){
	// 		if(angle == 0 || angle == 180){			
	// 			posArr = posArr_tablet_portrait;
	// 		}else{
	// 			posArr = posArr_pc;
	// 		}
	// 	}else{
	// 		posArr = posArr_pc;
	// 	}
	// 	elmAnime(posArr[0]);
	// 	changeEvent_sp();
	// }
	// posSet();
	const posSet = () => {
		const angle = screen.orientation.angle;
		if(window.matchMedia('(max-width:767px)').matches){
			if(angle == 0 || angle == 180){
				posArr = [
					{scale:2.5, x:100, y:160, elcol:'#FEFCF6', eltxt:'#fff', bg:'#F44500', txt:'#fff'},
				];
			}else{
				posArr = [
					{scale:1.4, x:70, y:35, elcol:'#FEFCF6', eltxt:'#fff', bg:'#F44500', txt:'#fff'},
				];
			}
		}else if(window.matchMedia('(max-width:1024px)').matches){
			posArr = [
				{scale:2.1, x:75, y:100, elcol:'#FEFCF6', eltxt:'#fff', bg:'#F44500', txt:'#fff'},
			];
		}else{
			posArr = posArr_pc;
			changeEvent_sp();
		}
		elmAnime(posArr[0]);
	}
	posSet();

	window.addEventListener('resize', function(){
		if(topkv_requestId){
			cancelAnimationFrame(topkv_requestId);
		}
		posSet();
	});

}



function logoswiper(){
	const swiper = new Swiper('#logoSwiper', {
		slidesPerView: 3,
		slidesPerGroup: 1,
		autoplay: {
			delay: 0,
		},
		speed: 2000,
		spaceBetween: 0,
		loop: true,
		keyboard: false,
		pagination: false,
		navigation: false,
		breakpoints: {
			768: {
				speed: 2000,
				slidesPerView: 5,
				slidesPerGroup: 1,
			},
		}
	});
}


function counterAnime(){
	const counterStart = (el) => {
		let _count = el.dataset.count;
		let _digit = 1;
		if(!Number.isInteger(Number(_count))){
			_digit = 0.1;
		}
		gsap.to(el, {
			textContent: _count,
			duration: 1,
			ease: "power1.in",
			snap : { textContent: _digit }
		});
	}
	gsap.utils.toArray('.counter').forEach(function (el) {
		const cou_scrollTrigger = ScrollTrigger.create({
			trigger: el,
			start: "top bottom-=10%",
			once: true,
			onEnter: self => {
				counterStart(el);
			},
		});
	});
}

let footer_txtloop;
function txtloop_Slider(nav = false, delay = 1000){
	if(typeof footer_txtloop !== 'undefined') {
		footer_txtloop.destroy();
	}
	let _navgation = false;
	let _touch = false;
	if(nav){
		_navgation = {
			prevEl: '#sw_prev',
			nextEl: '#sw_next',
		};
		_touch = true;
	}
	footer_txtloop = new Swiper('#txt_loop', {
		direction: 'vertical',
		slidesPerView: 1,
		speed: 600,
		spaceBetween: 0,
		loop: true,
		autoplay: {
			delay: delay,
		},
		keyboard: false,
		pagination: false,
		navigation: _navgation,
		allowTouchMove: _touch,
	});
}


function boxParallax(){
	gsap.utils.toArray('.para').forEach(tar => {
		const _trigger = tar.parentElement;
		const _y = tar.dataset.para;
		gsap.fromTo(tar, {yPercent: _y, xPercent:-50},
			{
				yPercent: _y*-1,
				xPercent:-50,
				ease: 'power1.out',
				scrollTrigger: {
					trigger: _trigger,
					start: 'top bottom',
					end: 'bottom top',
					scrub: 2,
				},
			}
		);
	});
}

function topDemoParallax(){
	gsap.utils.toArray('.demopara').forEach(tar => {
		const _trigger = tar.parentElement;
		const _y = tar.dataset.para;
		gsap.fromTo(tar, {yPercent: _y/2},
			{
				yPercent: _y*-1,
				ease: 'power1.out',
				scrollTrigger: {
					trigger: _trigger,
					start: 'top bottom',
					end: 'bottom top',
					scrub: 2,
				},
			}
		);
	});
}

function imgParallax(){
	gsap.utils.toArray('.paraimg').forEach(tar => {
		const _trigger = tar.parentElement;
		const _parentH = _trigger.clientHeight;
		const _tarH = tar.clientHeight;
		const _y = _tarH - _parentH - 1;
		gsap.fromTo(tar, {y: 0},
			{
				y: _y*-1,
				ease: 'power1.out',
				scrollTrigger: {
					trigger: _trigger,
					start: 'top bottom',
					end: 'bottom top',
					scrub: 1,
				},
			}
		);
	});
}


function videoPlay(){
	gsap.utils.toArray('#video_sec .video').forEach(target => {
		const iframe = target.querySelector('iframe');
		const player = new Vimeo(iframe);
		player.on('pause', function() {
			target.classList.remove('play');
		});
		target.addEventListener('click', function(){
			player.play();
			target.classList.add('play');
		});	
	});
}


function commentswiper(){
	const swiper = new Swiper('#commentSwiper', {
		slidesPerView: 1,
		autoplay: {
			delay: 2000,
		},
		speed: 600,
		spaceBetween: 20,
		loop: true,
		keyboard: false,
		pagination: false,
		navigation: false,
		navigation: {
			nextEl: '#snav_next',
			prevEl: '#snav_prev',
		},
	});
}


async function ScrollSmootherKill() {
	await new Promise((resolve, reject) => {
		ScrollTrigger.killAll();
		resolve();
	})
}


function hubspotFunc() {
	const hubspotForm = document.getElementById('hubspotForm');
	const portalid = hubspotForm.dataset.portalid;
	const formid = hubspotForm.dataset.formid;
	hbspt.forms.create({
    region: "na1",
    portalId: portalid,
    formId: formid,
		target: '#hubspotForm',
  });
}


var PageEvents = {
	loadFunc: function(){
		navInit();
		soundInit();
	},
	commonFunc: function(){
		this.pjaxReadyFunc();
		winH = $(window).height();
		winW = $(window).width();	
		pageScroll();
		nav_hide();
		nav_pagecheck();
		expandFunc();
		boxParallax();
		imgParallax();
		txtloop_Slider();
		elFunc('.el');
		hoverSoundFunc();
		OBSERVER.observeEvents(1);
	},
	pjaxReadyFunc: function(){
	},
	topFunc: async function(){
		body.addClass('top');
		topkvFunc();
		counterAnime();
		logoswiper();
		topDemoParallax();
	},
	productFunc: function(){
		videoPlay();
	},
	ourstoryFunc: function(){
		body.addClass('our_world nofoot_emp');
		logoswiper();
	},
	resourcesSingleFunc: function(){
		body.addClass('nofoot_emp');
	},
	whitepapersSingleFunc: function(){
		body.addClass('nofoot_emp');
		hubspotFunc();
	},
	formFunc: function(){
		body.addClass('nofoot_emp');
		commentswiper();
		hubspotFunc();
	},
	lpFunc: function(){
		body.addClass('lp');
		hubspotFunc();
	}
	
};

function pjaxSettings(){

	barba.use(barbaPrefetch);

	const replaceHead = function(data){
		const head = document.head;
		const newPageRawHead = data.next.html.match(/<head[^>]*>([\s\S.]*)<\/head>/i)[0];
		const newPageHead = document.createElement('head');
		newPageHead.innerHTML = newPageRawHead;
		const removeHeadTags = [ 
			"meta[name='keywords']"
			,"meta[name='description']"
			,"meta[property^='og']"
			,"meta[name^='twitter']"
			,"meta[itemprop]"
			,"link[itemprop]"
			,"link[rel='prev']"
			,"link[rel='next']"
			,"link[rel='canonical']"
		].join(',');
		const headTags = head.querySelectorAll(removeHeadTags)
		for(let i = 0; i < headTags.length; i++ ){
			head.removeChild(headTags[i]);
		}
		const newHeadTags = newPageHead.querySelectorAll(removeHeadTags)
		for(let i = 0; i < newHeadTags.length; i++ ){
			head.appendChild(newHeadTags[i]);
		}
	}

	var eventDelete = function eventDelete(e) {
		if (e.currentTarget.href === window.location.href) {
			e.preventDefault()
			e.stopPropagation()
			return
		}
	}

	var gaPush = function gaPush(pagename) {
		if (typeof ga === 'function' && Barba.HistoryManager.history.length >= 1) {
			ga('send', 'pageview', pagename);
		}
		if (typeof gtag === 'function' && Barba.HistoryManager.history.length >= 1) { 
			// gtag('config', 'G-Xxxxxxxx', {'page_path': pagename}); 
		}
	}

	const links = Array.prototype.slice.call(document.querySelectorAll('a[href]'));
		links.forEach(function (link) {
			link.addEventListener('click', function (e) {
			eventDelete(e)
		}, false)
	})

	var preventSettings = function preventSettings(el) {
		let href = el.getAttribute('href');
		if(href == null){
			href = el.getAttribute('xlink:href');
		}
		if(el.classList.contains('nopj')){
			return true;
		}
		let site_url = location.protocol + '//' + location.host;
		if (href.startsWith('/')) {
			return false;
		}
		if (!href.startsWith(site_url)) {
			el.setAttribute('target','_blank');
			return true;
		}
		let url = location.protocol + '//' + location.host + location.pathname;
		let extract_hash = href.replace(/#.*$/,"");
		if (href.startsWith(location.protocol + '//' + location.host)) {
			if (href.indexOf('#') > -1 && extract_hash != url ){
				return false;
			}
		}
		if (/\.(xlsx?|docx?|pptx?|pdf|jpe?g|png|gif|svg)/.test(href.toLowerCase())) {
			el.setAttribute('target','_blank');
			return true;
		}
	}

	async function scroll(data) {
		await new Promise((resolve, reject) => {
			let scrltarget = 0;
			if(data.trigger === "back") {
				scrltarget = scrollPosY;
			}
			scrollPosY = barba.history.current.scroll.y;
			if(data.trigger === "forward"){
				scrltarget = scrollForwardPosY;
			}

			if(location.hash){
				var anchor = document.querySelector( location.hash );
				if(anchor){
					var target = location.hash;
					gsap.to(window, { duration:0, scrollTo:{y:target, autoKill:false, offsetY:tar_offset}, ease:'power2', onComplete:function(){
						body.addClass('scrldown');
						resolve();
					}});	
				}else{
					gsap.to(window, { duration:0, scrollTo:0, ease:'power2', onComplete:function(){
						resolve();
					}});
				}
			}else{
				gsap.to(window, { duration:0, scrollTo:scrltarget, ease:'power2', onComplete:function(){
					body.removeClass('scrldown');
					resolve();
				}});
			}
		})
	}


	barba.init({
		prevent: function prevent(_ref) {
			var el = _ref.el;
			return preventSettings(el);
		},
		timeout: 10000,			
		transitions: [{
			name: 'transition',
			sync:true,
			leave:function(data) {
				const done = this.async();
				gsap.to('footer', {opacity:0, duration:0.2})
				gsap.to(data.current.container, {opacity:0, duration:0.2, onComplete:function(){
					ScrollSmootherKill();
					done();
				}});
			},
			enter:function(data) {
				const done = this.async();
				const $elm = $(data.next.container);
				$elm.css({'opacity':0});
				done();
			}
		}]
	})

	barba.hooks.beforeLeave(() => {
		body.removeClass('ready top inpage navopen scrldown our_world nofoot_emp lp');
	});

	barba.hooks.beforeEnter((data) => {
		if( OBSERVER.handlers.length ){
			OBSERVER.clearEvents();
		}
		replaceHead(data);
		gaPush(location.pathname);
	});

	if (history.scrollRestoration) {
		history.scrollRestoration = 'manual';
	}
	var scrollPosY = 0;
	var scrollForwardPosY = 0;
	barba.hooks.leave((data) => {
		scrollForwardPosY = data.current.container.scroll.y;
	});

	barba.hooks.after((data) => {
		async function callerFun(){
			await scroll(data);
			pageCheck();
			gsap.to('footer', {opacity:1, duration:0.6})
			gsap.to(data.next.container, {opacity:1, duration:0.6, onComplete:function(){
			}});
		}
		$('#container').imagesLoaded( function() {
			callerFun();
		});
	});
}

function pageCheck(){
	PageEvents.commonFunc();

	if( document.getElementById('top') ){
		PageEvents.topFunc();
	}else{
		body.addClass('inpage');
	}
	if( document.getElementById('product') ){
		PageEvents.productFunc();
	}
	if( document.getElementById('our-story') ){
		PageEvents.ourstoryFunc();
	}
	if( document.getElementById('resources') ){
		PageEvents.resourcesSingleFunc();
	}
	if( document.getElementById('whitepapers') ){
		PageEvents.whitepapersSingleFunc();
	}
	if( document.getElementById('form_page') ){
		PageEvents.formFunc();
	}
	if( document.getElementById('landing-page') ){
		PageEvents.lpFunc();
	}
	
	body.addClass('ready');
}

function initialize(){
	window.addEventListener('DOMContentLoaded', function(){
		PageEvents.loadFunc();
		pageCheck();
		pjaxSettings();
	});
}

initialize();

})();
})( jQuery );

}